"use client"

import { Box, Button, Center, Text, Flex, HStack, useColorModeValue } from "@chakra-ui/react";
import Logo from "@/components/Logo";
import Link from "next/link"
import { useSession } from "next-auth/react";


const Home = () => {
  const {data: sessionData} = useSession()
  return (
    <Center minHeight="100vh" bg={useColorModeValue("gray.50", "gray.800")} p={5}>
      <Box mt="-3">
        <Box  textAlign="center">
          <Logo size="7xl"/>
        </Box>
        <Box mb="4">
          <Text textAlign="center" fontSize="2xl">Intégrez facilement des moyens de paiements dans vos pages hotSpot</Text>
        </Box>
        <Box>
          <Flex justify="center">
            <HStack spacing="20px">
              {sessionData ? 
                <Button variant="solid" as={ Link } colorScheme="brand" href="/dashboard">DashBoard</Button>
                :<Button variant="solid" as={ Link } colorScheme="brand" href="/auth/signin">Se connecter</Button>
              }
              <Button variant="outline" as="a" href="https://3jme-group.com" target="_blank">Nous contacter</Button>
            </HStack>
          </Flex>
        </Box>
      </Box>
    </Center>
  )
}

export default Home
