'use client'

import { Text, useColorModeValue } from '@chakra-ui/react'

export default function Logo ({size="3xl"}) {
    return (
        <Text as="span" fontSize={size} fontWeight="bold">
            <Text as="span" color="brand.500">Mikro</Text>
            <Text as="span" color={useColorModeValue('gray.900', 'white')}>Pay</Text>
        </Text>
    )
}